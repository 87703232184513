<template>
  <div class="products-by-subcategory">
    <v-card flat v-if="subcategory">
      <v-card-title>
        <v-row dense align="center" justify="start">
          <h2 class="fw-700 primary--text">
            Designs for {{ subcategory.title | ucwords }}
          </h2>
          <v-spacer />
          <v-btn
            class="text-none mx-1"
            @click="goToMainCategory"
            depressed
            color="primary"
          >
            {{ subcategory.category.title | ucwords }}
          </v-btn>
          <v-autocomplete
            v-model="searchModel"
            :items="searchResults"
            :loading="isSearchLoading"
            :search-input.sync="searchKeyword"
            color="primary"
            class="col-md-3 col-sm-12"
            hide-no-data
            hide-selected
            dense
            hide-details="auto"
            clearable
            outlined
            item-text="title"
            item-value="id"
            :placeholder="`Search ${subcategory.title} templates`"
            prepend-inner-icon="mdi-magnify"
            return-object
          >
            <template v-slot:item="{ item }">
              <span>
                <v-list-item>
                  <v-list-item-avatar tile class="mx-1">
                    <Attachment
                      :item="item.previews[0]"
                      prefer-thumb
                      :style="`max-height:100%;width: auto;`"
                    />
                  </v-list-item-avatar>
                  <v-list-item-title
                    class="primary--text fs-14"
                    v-text="item.title"
                  ></v-list-item-title>
                </v-list-item>
              </span>
            </template>
          </v-autocomplete>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row dense class="px-3">
          <template v-if="products.length">
            <v-col
              md="3"
              sm="4"
              xs="12"
              cols="12"
              class="pa-5"
              v-for="item in products"
              :key="item.id"
            >
              <ProductCard
                :product="item"
                :max-width="250"
                @viewProduct="viewProduct(item)"
                @addToLikes="add_to_likes(item)"
                @deleteProduct="confirmDeleteProduct(item)"
                @editProduct="editProduct(item)"
                @shareProduct="shareProduct(item)"
              />
            </v-col>
          </template>
          <template v-else>
            <v-col cols="12">
              <Empty
                icon="mdi-image-off-outline"
                style="height: 500px"
                headline="No template found!"
              />
            </v-col>
          </template>
        </v-row>
        <v-row dense class="px-2">
          <v-spacer />
          <v-btn
            v-if="next_page_url"
            text
            depressed
            outlined
            class="text-none px-5 mt-5"
            :loading="loading_more"
            @click="getMoreSubCategoryProducts"
          >
            View More
          </v-btn>
          <v-btn v-else disabled text class="text-none px-5 mt-5">
            No more
          </v-btn>
          <v-spacer />
        </v-row>
      </v-card-text>
    </v-card>
    <ProductViewDialog
      v-model="product_view_dialog"
      :product="activeProduct"
      @item-liked="itemLiked"
    />
    <DeleteDialog
      :open-dialog.sync="product_delete_dialog"
      title="Delete Product"
      text-content="Are you sure you want to delete this product? This will also delete all pending orders of this product "
      @delete="deleteProduct(delete_callback)"
    />
  </div>
</template>

<script>
import request from '@/services/axios_instance'
import ProductViewDialog from '../components/ProductViewDialog.vue'
import { offers_mixin } from '../mixin'
import DeleteDialog from '@/common/DeleteDialog.vue'
import ProductCard from '../components/ProductCard.vue'
import _ from 'lodash'
export default {
  name: 'ProductBySubCategory',
  mixins: [offers_mixin],
  components: {
    ProductCard,
    DeleteDialog,
    ProductViewDialog
  },
  data: () => ({
    subcategory: null,
    products: [],
    next_page_url: null,
    loading_more: false,
    filter: 'all',
    searchModel: null,
    searchResults: [],
    isSearchLoading: false,
    searchKeyword: null
  }),
  computed: {
    paths() {
      return [
        {
          text: 'Dashboard',
          disabled: false,
          route: { name: 'default-content' }
        },
        {
          text: 'Offers',
          disabled: false,
          route: { name: 'offers', params: { type: 'products' } }
        },
        {
          text: this.subcategory ? this.subcategory.title : 'subcategory',
          disabled: true,
          route: null
        }
      ]
    },
    computed_all() {
      return (
        _.sumBy(this.subcategory.subcategories, 'counts') +
        this.subcategory.counts
      )
    },
    filters() {
      let list = _.cloneDeep(this.subcategory.subcategories)
      list.unshift({ title: 'All', slug: 'all' })
      return list
    }
  },
  watch: {
    $route: {
      handler(val) {
        if (val) {
          this.getGraphicType(val.params.id)
          this.getGraphicTypeProducts(val.params.id)
        }
      },
      immediate: true,
      deep: true
    },
    filter(val) {
      if (val === 'all') val = this.$route.params.id
      this.getGraphicTypeProducts(val)
    },
    searchKeyword(val) {
      if (this.searchResults.length > 0) return
      if (this.isSearchLoading) return

      this.isSearchLoading = true
      request
        .get(
          `api/graphic-types/${
            this.subcategory.id
          }/search/products?keyword=${val.trim()}`
        )
        .then(({ data }) => {
          this.searchResults = data
        })
        .finally(() => (this.isSearchLoading = false))
    },
    searchModel: {
      handler: function (val) {
        if (val) {
          this.viewProduct(val)
        }
      },
      immediate: true
    }
  },
  methods: {
    getGraphicType(id) {
      request
        .get(`api/graphic-types/${id}`)
        .then(({ data }) => {
          this.subcategory = data
        })
        .finally(() => {
          this.$event.$emit('path-change', this.paths)
        })
    },
    getGraphicTypeProducts(slug) {
      this.loading_more = true
      request
        .get(`api/graphic-types/${slug}/products`)
        .then(({ data }) => {
          this.products = data.data
          this.next_page_url = data.next_page_url
        })
        .finally(() => (this.loading_more = false))
    },
    getMoreSubCategoryProducts() {
      this.loading_more = true
      request
        .get(this.next_page_url)
        .then(({ data }) => {
          this.products.push(...data.data)
          this.next_page_url = data.next_page_url
        })
        .finally(() => (this.loading_more = false))
    },
    add_to_likes(item) {
      this.likeUnlikeProduct(item, (data) => {
        let index = this.products.findIndex((i) => i.id === item.id)
        if (~index) {
          this.products[index].is_liked = data.is_liked
          this.products[index].likes_count = data.likes_count
        }
      })
    },
    delete_callback(data) {
      let index = this.products.findIndex((i) => i.id === data.id)
      if (~index) {
        this.products.splice(index, 1)
      }
    },
    itemLiked(item) {
      let index = this.products.findIndex((i) => i.id === item.id)
      if (~index) {
        this.products[index].is_liked = item.is_liked
        this.products[index].likes_count = item.likes_count
      }
    },
    goToMainCategory() {
      this.$router.push({
        name: 'offers-by-category',
        params: { id: this.subcategory.category.slug }
      })
    }
  }
}
</script>

<style scoped lang="scss"></style>
